<template>
    <div class="emp_dashboard_section">
      <div class="add_task_section">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">All Tasks</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Today Task's</button>
            </li>
        </ul>
        <div class="search_div">
            <div class="search_flex">
                <div class="input_div">
                    <input
                        type="text"
                        placeholder="Enter your task name"
                        v-model="taskName"
                    />
                </div>
                <div class="cal_drop_div">
                    <div class="calender_div">
                        <datepicker :full-month-name="true" v-model="picked" />
                    </div>
                    <div class="dropdown_div" ref="dropdownDiv">
                        <div class="dropdown_list" ref="dropdown">
                            <button @click="toggleDropdown">
                                <span>{{ selectedAgent ? getFirstWord(selectedAgent.filename) : 'Select tag' }}</span>
                                <span :class="{ 'rotate': dropdownOpen }">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                                    </svg>
                                </span>
                            </button>
                            <ul v-show="dropdownOpen">
                                <li v-for="(agent, index) in agents" :key="index" @click="selectAgent(agent)">
                                    {{ getFirstWord(agent.filename) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button_div">
                <button type="button" @click="addTask">Add</button>
            </div>
        </div>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="add_task_details">
                    <div class="task_de_div">
                        <h2 >Today</h2>
                        <ul>
                            <li v-for="(task, index) in taskList" :key="index">
                                <div class="today_background" v-if="taskList.length && determineDateStatus(task.task_date)== 'present'">
                                    <div class="d-flex div_border">
                                        <div class="today_heading">
                                            <h2 :class="{ 'line-through': task.isChecked }">
                                                {{ task.task_name }}
                                                <span :class="{'urgent-span': task.tag === 'Urgent', 'due-span': task.tag === 'Due'}">
                                                    {{ task.tag }}
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="hover_calender">
                                              <datepicker :full-month-name="true" v-model="picked" @change="changeTaskDate"/>
                                            </div>
                                            <div class="today_click">
                                                <input
                                                    class="styled-checkbox"
                                                    type="checkbox"
                                                    v-model="task.isChecked"
                                                    :id="'styled-checkbox-today-' + index"
                                                />
                                                <label :for="'styled-checkbox-today-' + index">.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
  
                        <h2 >Upcoming</h2>
                        <ul>
                            <li v-for="(task, index) in taskList" :key="index">
                                <div class="today_background" v-if="taskList.length && determineDateStatus(task.task_date)== 'future'">
                                    <div class="d-flex div_border">
                                        <div class="today_heading">
                                            <h2 :class="{ 'line-through': task.isChecked }">
                                                {{ task.task_name }}
                                                <span :class="{'urgent-span': task.tag === 'Urgent', 'due-span': task.tag === 'Due'}">
                                                    {{ task.tag }}
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="hover_calender">
                                              <datepicker :full-month-name="true" v-model="picked" />
                                            </div>
                                            <div class="today_click">
                                                <input
                                                    class="styled-checkbox"
                                                    type="checkbox"
                                                    v-model="task.isChecked"
                                                    :id="'styled-checkbox-tomorrow-' + index"
                                                />
                                                <label :for="'styled-checkbox-tomorrow-' + index">.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="add_task_details">
                    <div class="task_de_div">
                        <h2 >Today</h2>
                        <ul>
                            <li v-for="(task, index) in taskList" :key="index">
                                <div class="today_background" v-if="taskList.length && determineDateStatus(task.task_date)== 'present'">
                                    <div class="d-flex div_border">
                                        <div class="today_heading">
                                            <h2 :class="{ 'line-through': task.isChecked }">
                                                {{ task.task_name }}
                                                <!-- {{ determineDateStatus(task.task_date) }} -->
                                                <span :class="{'urgent-span': task.tag === 'Urgent', 'due-span': task.tag === 'Due'}">
                                                    {{ task.tag }}
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="today_click">
                                            <input
                                                class="styled-checkbox"
                                                type="checkbox"
                                                v-model="task.isChecked"
                                                :id="'styled-checkbox-today-' + index"
                                            />
                                            <label :for="'styled-checkbox-today-' + index">.</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <h2 >Previous Due</h2>
                        <ul>
                            <li v-for="(task, index) in taskList" :key="index">
                                <div class="today_background" v-if="taskList.length && determineDateStatus(task.task_date)== 'past' && task.status==false">
                                    <div class="d-flex div_border">
                                        <div class="today_heading">
                                            <h2 :class="{ 'line-through': task.isChecked }">
                                                {{ task.task_name }}
                                                <!-- {{ determineDateStatus(task.task_date) }} -->
                                                <span :class="{'urgent-span': task.tag === 'Urgent', 'due-span': task.tag === 'Due'}">
                                                    {{ task.tag }}
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="today_click">
                                            <input
                                                class="styled-checkbox"
                                                type="checkbox"
                                                v-model="task.isChecked"
                                                :id="'styled-checkbox-today-' + index"
                                            />
                                            <label :for="'styled-checkbox-today-' + index">.</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <h2 >Completed</h2>
                        <ul>
                            <li v-for="(task, index) in taskList" :key="index">
                                <div class="today_background" v-if="taskList.length && task.status==true">
                                    <div class="d-flex div_border">
                                        <div class="today_heading">
                                            <h2 :class="{ 'line-through': task.stataus }">
                                                {{ task.task_name }}
                                                <!-- {{ determineDateStatus(task.task_date) }} -->
                                                <span :class="{'urgent-span': task.tag === 'Urgent', 'due-span': task.tag === 'Due'}">
                                                    {{ task.tag }}
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="today_click">
                                            <input
                                                class="styled-checkbox"
                                                type="checkbox"
                                                v-model="task.stataus"
                                                :id="'styled-checkbox-today-' + index"
                                                @change="handleCheckboxChange(task)"
                                            />
                                            <label :for="'styled-checkbox-today-' + index">.</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from 'vuejs3-datepicker';
  const mirror = createLocal()
  const storage = mirror._prx
  import { createLocal, createSession, createStorage } from 'the-storages'
  import { BASE_URL } from "../../config";
  import axios from "axios";
  export default {
    name: 'AddTaskData',
    components: {
      Datepicker,
    },
    data() {
        return {
            taskName: '',
            picked: new Date(),
            dropdownOpen: false,
            selectedAgent: null,
            agents: [
                { filename: 'Urgent' },
                { filename: 'Due' },
            ],
            tasksToday: [],
            tasksTomorrow: [],
            taskList:''
        };
    },
    created(event){
        this.taskListing();
    },

    computed: {
        todayDate() {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return today;
        },
        tomorrowDate() {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);
            return tomorrow;
        },
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        selectAgent(agent) {
            this.selectedAgent = agent;
            this.dropdownOpen = false;
        },
        getFirstWord(str) {
            return str.split(' ')[0];
        },
        handleClickOutside(event) {
            if (this.dropdownOpen && !this.$refs.dropdownDiv.contains(event.target)) {
                this.dropdownOpen = false;
            }
        },
        formatDate(date) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        async addTask() {
            if (this.taskName.trim() === '') {
                alert('Task name cannot be empty!');
                return;
            }
  
            const newTask = {
                name: this.taskName,
                tag: this.selectedAgent ? this.getFirstWord(this.selectedAgent.filename) : '',
                date: this.picked,
                isChecked: false,
                id: mirror.ID
            };
  
            if (this.isToday(this.picked)) {
                this.tasksToday.push(newTask);
            } else if (this.isTomorrow(this.picked)) {
                this.tasksTomorrow.push(newTask);
            } else {
                alert('Tasks can only be added for today or tomorrow.');
            }

            console.log("newTask newTask newTask newTask",newTask);
            
            await axios.post(BASE_URL + '/api/addTaskEmp',newTask)
                            .then((response)=>{
                            console.log(response.data);                            
                        })

            this.taskListing();
            this.taskName = '';
            this.selectedAgent = null;
            this.picked = new Date();
        },

        async taskListing(){
            await axios.post(BASE_URL + '/api/fetchTaskList',{id:mirror.ID})
                .then((response)=>{
                console.log(response.data);  
                this.taskList = response.data.list;                          
            })
        },
        isToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return today.getTime() === new Date(date).setHours(0, 0, 0, 0);
        },
        isTomorrow(date) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);
            return tomorrow.getTime() === new Date(date).setHours(0, 0, 0, 0);
        },
        determineDateStatus(dateString) {
            // Convert the date string to a Date object
            const date = new Date(dateString);
            // Get the current date
            const now = new Date();

            // Remove time part from both dates to compare only dates
            const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const nowOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            if (dateOnly.getTime() < nowOnly.getTime()) {
                return 'past';
            } else if (dateOnly.getTime() > nowOnly.getTime()) {
                return 'future';
            } else {
                return 'present';
            }
        },
        async handleCheckboxChange(task){
            console.log("task",task);
            const newTask = {
                name: this.taskName,
                tag: this.selectedAgent ? this.getFirstWord(this.selectedAgent.filename) : '',
                date: this.picked,
                isChecked: task.status == true ? false : true,
                id: mirror.ID
            };
            await axios.post(BASE_URL + '/api/editTaskEmp',newTask)
                            .then((response)=>{
                            // console.log(response.data);                            
            })

        },

        async changeTaskDate(){
            console.log("change date",this.picked);
            
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
  };
  </script>
  
  