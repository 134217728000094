<template>
    <div class="emp_dashboard_section">
      <div class="emp_dashboard_left">
        <EmpDashboardLeftside />
      </div>
      <div class="emp_dashboard_right">
        <EmpDashboardHeader />
        <div class="emp_dashboard_content">
          <AddTaskData />
        </div>
      </div>
    </div>
  </template>

<script>
import "../assets/empcss/empcss.css";
import EmpDashboardHeader from "@/components/EmpDashboard/EmpDashboardHeader.vue";
import EmpDashboardLeftside from "@/components/EmpDashboard/EmpDashboardLeftside.vue";
import AddTaskData from "@/components/EmpDashboard/AddTaskData.vue";

export default {
  name: 'AddTask',
  components: {
    EmpDashboardHeader,
    EmpDashboardLeftside,
    AddTaskData,
  },
};
</script>